import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/elements/404.vue')
    },
    {
        path: '/',
        name: 'HomeStartUp',
        component: () => import(/* webpackChunkName: "HomeStartUp" */ '../views/HomeStartUp.vue'),
    },
    {
        path: '/Case-Study-Australian-Buying-Property',
        name: 'Case-Study-Australian-Buying-Property',
        component: () => import(/* webpackChunkName: "BlogDetails" */ '../views/blog/Case-Study-Australian-Buying-Property.vue'),
    },

    {
        path: '/Case-Study-Buying-property-temporary-visa',
        name: 'Case-Study-Buying-property-temporary-visa',
        component: () => import(/* webpackChunkName: "BlogDetails" */ '../views/blog/Case-Study-Buying-property-temporary-visa.vue'),
    },
    {
        path: '/Case-Study-Australian-expat-with-foreign-income',
        name: 'Case-Study-Australian-expat-with-foreign-income',
        component: () => import(/* webpackChunkName: "BlogDetails" */ '../views/blog/Case-Study-Australian-expat-with-foreign-income.vue'),
    },
    {
        path: '/Case-Study-retirement-seeking-long-term-loan',
        name: 'Case-Study-retirement-seeking-long-term-loan',
        component: () => import(/* webpackChunkName: "BlogDetails" */ '../views/blog/Case-Study-retirement-seeking-long-term-loan.vue'),
    },
    {
        path: '/Case-Study-Wedding-Personal-Loan',
        name: 'Case-Study-Wedding-Personal-Loan',
        component: () => import(/* webpackChunkName: "BlogDetails" */ '../views/blog/Case-Study-Wedding-Personal-Loan.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/pages/PrivacyPolicy.vue'),
    },
    {
        path: '/Calculator-LoanRepayment',
        name: 'LoanRepayment',
        component: () => import(/* webpackChunkName: "LoanRepayment" */ '../views/calculators/LoanRepayment.vue'),
    },
    {
        path: '/Calculator-BorrowingPower',
        name: 'BorrowingPower',
        component: () => import(/* webpackChunkName: "BorrowingPower" */ '../views/calculators/BorrowingPower.vue'),
    },
    {
        path: '/Calculator-StampDuty',
        name: 'StampDuty',
        component: () => import(/* webpackChunkName: "StampDuty" */ '../views/calculators/StampDuty.vue'),
    },
    {
        path: '/Calculator-PropertyBuyingCost',
        name: 'PropertyBuyingCost',
        component: () => import(/* webpackChunkName: "PropertyBuyingCost" */ '../views/calculators/PropertyBuyingCost.vue'),
    },
    {
        path: '/Calculator-PropertySellingCost',
        name: 'PropertySellingCost',
        component: () => import(/* webpackChunkName: "PropertySellingCost" */ '../views/calculators/PropertySellingCost.vue'),
    },
    {
        path: '/Calculator-IncomeTax',
        name: 'IncomeTax',
        component: () => import(/* webpackChunkName: "IncomeTax" */ '../views/calculators/IncomeTax.vue'),
    },
    {
        path: '/CalendarBooking',
        name: 'CalendarBooking',
        component: () => import(/* webpackChunkName: "CalendarBooking" */ '../views/CalendarBooking.vue'),
    },
];
  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
  
export default router;
